@import 'critical';

/* styles.scss */
@import "partials/popup";
@import "partials/special";
@import "partials/impressions";
@import "partials/look";
@import "partials/newsfeed";
@import "partials/newsarchive";
@import "partials/subjects";
@import "partials/events";
@import "partials/blog";
@import "partials/banner";
@import "partials/post";
@import "partials/sidebar";
@import "partials/newsletter";
@import "partials/brochures";
@import "partials/footer";
@import "partials/sitemap";
@import "partials/service";
@import "partials/brands";
@import "partials/news";
@import "partials/newstv";
@import "partials/history";
@import "partials/pagination";
@import "partials/press";
